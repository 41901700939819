import React, { useEffect, useMemo, useState } from 'react'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import PanelController from './PanelController'
import useStore, { LOCAL_STORAGE_DEBUG_KEY } from 'state/knovStore'
import api from 'api/api'
import KnovModal from 'components/shared/KnovModal'
import CryptoPayModal from './shared/CryptoPayModal'
import StoryModal from 'components/shared/StoryModal'
import LoadingScreen from 'components/shared/LoadingScreen'
import * as Sentry from '@sentry/react'
import 'styles/global.scss'
import useInitNotifications from './useInitNotifications'
import { QueryClientProvider } from '@tanstack/react-query'
import queryClient from 'api/queryClient'
import * as cache from 'state/cache'
import * as localForage from 'localforage'
import useUserChannel from 'state/channels/useUserChannel'
import {
    DEFAULT_QUICK_BSV_LOCK_SATS,
    DEFAULT_QUICK_BSV_LOCK_BLOCKS,
    DEFAULT_BSV_UNIT,
} from 'lib/bsv-util'
import { cacheAnswer } from 'state/cache'
import { PanelState } from 'state/PanelState'
import { BsvWalletType, initWallet, PandaWallet, SHUAllet } from 'wallets/wallets'

window.get = useStore.getState().get
window.set = useStore.getState().set
window.cache = cache
window.localForage = localForage

export default function StateProvider({ filter }) {
    const serverFilter = gon.currentUser?.space_options?.filter
    const nonEmptyServerFilter =
        serverFilter && Object.keys(serverFilter).length > 0 ? serverFilter : null
    filter = filter || nonEmptyServerFilter || { public: true, order: 'time' }

    const loadingSpaceId = useStore(state => state.loadingSpaceId)
    const activeSpaceId = useStore(state => state.activeSpaceId)
    //console.log('>>> Stateprovider filter: ', filter)

    const set = useStore.getState().set
    const get = useStore.getState().get

    let middleFilter = filter
    const [didExpoInit, setDidExpoInit] = useState(false)

    let middleQuest

    useEffect(function initializeStateOnFirstRender() {
        const initialState = {
            panels: {
                ...get().panels,
                // Don't replace the centered panel, and only the empty panels to force a reThis is a HACK to keep the startingIndex and prevCenteredPanelId consistent... there should be a better way to do this. Also, we need the initial panels: state set with 3 panels instead of [] in knovStore for some reason to get mobile to show up correctly.
                state: [
                    new PanelState({ empty: true }),
                    new PanelState({
                        panelId: get().panels.state[1].panelId,
                        filter: filter,
                    }),
                    new PanelState({ empty: true }),
                ],
            },
            currentUserBsvBalance: null,
            currentUserBsvAddress: null,

            selectedAnswers: {},

            quickBsvLockSats:
                gon.currentUser?.space_options?.quickBsvLockSats || DEFAULT_QUICK_BSV_LOCK_SATS,
            quickBsvLockBlocks:
                gon.currentUser?.space_options?.quickBsvLockBlocks || DEFAULT_QUICK_BSV_LOCK_BLOCKS,
            bsvUnit: gon.currentUser?.space_options?.bsvUnit || DEFAULT_BSV_UNIT,

            userSpaces: [],
            userStreams: [],
            userFeatures: gon?.currentUser?.features || [],
            agentQuestIds: gon.currentUser?.space_options?.agent_quest_ids || [],
            questAgentModels: gon.currentUser?.space_options?.quest_agent_models || {},
            // Left Panel.
            activeHistoryTab: gon.currentUser?.options?.['activeHistoryTab'] || 'history',
            starredQuestIds:
                gon.currentUser?.starred_quest_ids?.reduce(
                    (acc, id) => ({ ...acc, [id]: true }),
                    {},
                ) || {},
            questHistory: [],
            // Middle Panel.
            middleFilter,
            middleQuest: middleQuest || null,
            middleQuests: [],
            middleCount: -1, //props.middleCount, // Could be quest or notification count.
            numNoti: filter.numNoti || 0,
            // Right Panel.
            childQuests: filter.childQuests,
            rightQuery: filter.rightQuery,
            rightParentAnswer: filter.rightParentAnswer,
            rightCount: filter.rightCount,

            filterUser: null,
            users: null,
            publicTeams: null,
            knovigatorSpace: null,
            middlePage: 1,
            rightPage: 1,
            //isModalOpen: false,
            isStoryOpen: false,
            publicUser: filter.publicUser,
            showSidebar: false,
            knovBotPendingStatus: {}, // list of quest ids that are waiting for a response from knovbot
            currentUserBsvWalletType:
                gon?.currentUser?.options?.bsv_wallet_type || BsvWalletType.SHUALLET,
            answerJobs: {},
            DEBUG: localStorage.getItem(LOCAL_STORAGE_DEBUG_KEY) === 'true',
        }

        const doInitMobileNotifications = async () => {
            const expo_noti_token = window.EXPO_PUSH_TOKEN
            const { id: userId } = gon?.currentUser
            if (expo_noti_token && userId) {
                await api.updateUser(userId, {
                    expo_noti_token,
                })
                console.log('Set mobile notification token for user!')
            }
            // alert(
            //     `is_mobile: ${window.KNOVIGATOR_IS_MOBILE}. Set mobile notification token for user!
            //     token = '${expo_noti_token}'`,      s
            // )
        }

        if (window.KNOVIGATOR_IS_MOBILE && !didExpoInit) {
            // give outer expo layer time to inject the expo token
            setTimeout(() => {
                doInitMobileNotifications()
                setDidExpoInit(true)
            }, 5000)
        }

        // hack to make sure spaces are in sync with gon
        // TODO: get the data modeling for this sorted out properly,
        //      inconsistencies here will lead to bugs
        if (gon?.currentUser?.space_id != initialState.activeSpaceId) {
            gon.currentUser.space_id = initialState.activeSpaceId
            gon.currentUser.space = initialState.userSpaces.find(
                s => s.id == initialState.activeSpaceId,
            )
        }

        set(initialState)
        gon?.currentUser ? api.ensureBsvWallet() : null

        console.log('>>> Set initial state: ', initialState)
    }, [])

    useEffect(function getKnovigatorSpaceOnMount() {
        api.getSpace(gon.KNOVIGATOR_SPACE_ID).then(knovigatorSpace => {
            set({ knovigatorSpace })
        })
    }, [])

    useEffect(
        function getTeams() {
            if (gon.currentUser && activeSpaceId)
                api.getTeams({ space_id: activeSpaceId }).then(teams => {
                    set({ userStreams: teams })
                })
        },
        [gon.currentUser, activeSpaceId],
    )

    useEffect(
        function getUserSpaces() {
            if (gon.currentUser)
                api.getSpaces().then(spaces => {
                    set({ userSpaces: spaces })
                })
        },
        [gon.currentUser],
    )

    useEffect(
        function getUsersOnMount() {
            if (gon.currentUser) {
                api.getUsers().then(users => {
                    set({ users })
                })
            }
        },
        [gon.currentUser, activeSpaceId],
    )

    useEffect(
        function getPublicTeamsOnMount() {
            api.getTeams({ space_id: activeSpaceId, public: true }).then(publicTeams => {
                set({ publicTeams })
            })
        },
        [activeSpaceId, gon.currentUser],
    )

    useInitNotifications(middleFilter, filter.middleQuest)

    useEffect(() => {
        const fetchBsvData = async () => {
            if (gon?.currentUser?.id) {
                // these will set the currentUserBsvBalance and
                // currentUserBsvAddress in BSV Wallet component via socket.
                await Promise.all([api.ensureBsvWallet(), api.getBsvAddress(), api.getBsvBalance()])
            }
        }
        fetchBsvData()
    }, [gon?.currentUser?.id])

    useUserChannel()

    //console.log('RENDER STATE PROVIDER')
    return (
        <Sentry.ErrorBoundary fallback={'An error has occured'}>
            <QueryClientProvider client={queryClient}>
                {loadingSpaceId ? (
                    <LoadingScreen spaceId={loadingSpaceId || activeSpaceId} />
                ) : (
                    <PanelController />
                )}
                <KnovModal />
                <CryptoPayModal />
                <StoryModal />
                {/*<ReactQueryDevtools initialIsOpen={false} />*/}
            </QueryClientProvider>
        </Sentry.ErrorBoundary>
    )
}
