import React from 'react'
import Modal from 'react-modal'
import ConfirmMerge from '../modals/ConfirmMerge'
import ExportQuest from '../modals/ExportQuest'
import ImportText from '../modals/ImportText'
import VideoRecorder from '../answers/VideoRecorder'
import ScreenRecorder from '../answers/ScreenRecorder'
import Reload from '../modals/Reload'
import NewStream from '../modals/NewStream'
import NewSpace from 'components/modals/NewSpace'
import UserImageUpdate from 'components/modals/UserImageUpdate'
import SpaceImageUpdate from 'components/modals/SpaceImageUpdate'
import StreamImageUpdate from 'components/modals/StreamImageUpdate'
import useStore from 'state/knovStore'
import HandCashError from '../modals/HandCashError'
import styles from 'components/shared/modal.module.scss'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'
import Wallet from 'components/modals/Wallet'
import SpaceIcon from 'components/spaces/SpaceIcon'
import { Wallet as WalletIcon } from 'lucide-react'

Modal.setAppElement('#knovigator-body')

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
}

export default function KnovModal(props) {
    const isModalOpen = useStore(state => state.isModalOpen)
    const closeModal = useStore(state => state.actions.modalActions.closeModal)
    const modalParams = useStore(state => state.modalParams) || props.modalParams

    const logoSize = '36px'
    const hiddenModal = modalParams && modalParams.hidden

    const mobileStyles = isMobile ? styles.mobileKnovModal : null
    const mobileRemoveBtnStyles = isMobile ? styles.mobileRemoveBtn : null

    return hiddenModal ? (
        <div style={{ height: 0, width: 0 }}>{getModalContent(modalParams, closeModal)}</div>
    ) : (
        <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Knov Modal"
        >
            <div id="knov-modal" className={cn('knov-modal-comp', styles.knovModal, mobileStyles)}>
                <div className={styles.knovModalHeader}>
                    <div className={styles.knovModalBrand}>
                        {modalParams?.icon ? (
                            <div className={styles.modalIcon}>
                                {modalParams.icon === 'WalletIcon' ? (
                                    <WalletIcon size={40} />
                                ) : (
                                    <div className={styles.fontIcon}>
                                        <i className={modalParams.icon} />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <SpaceIcon />
                        )}
                        {modalParams?.title && (
                            <span className={styles.modalTitle}>{modalParams.title}</span>
                        )}
                    </div>

                    <div
                        className={cn(styles.removeBtn, mobileRemoveBtnStyles)}
                        onClick={closeModal}
                    >
                        <i className="fa fa-close fa-lg"></i>
                    </div>
                </div>

                <div className="knov-modal-content">{getModalContent(modalParams, closeModal)}</div>
            </div>
        </Modal>
    )
}

function getModalContent(modalParams, closeModal) {
    if (!modalParams) return null

    switch (modalParams.type) {
        case 'ConfirmMerge':
            return <ConfirmMerge modalParams={modalParams} closeModal={closeModal} />
        case 'ExportQuest':
            return <ExportQuest modalParams={modalParams} closeModal={closeModal} />
        case 'ImportText':
            return <ImportText modalParams={modalParams} closeModal={closeModal} />
        case 'VideoRecorder':
            return <VideoRecorder modalParams={modalParams} closeModal={closeModal} />
        case 'ScreenRecorder':
            return <ScreenRecorder modalParams={modalParams} closeModal={closeModal} />
        case 'Reload':
            return <Reload modalParams={modalParams} closeModal={closeModal} />
        case 'NewStream':
            return <NewStream modalParams={modalParams} closeModal={closeModal} />
        case 'NewSpace':
            return <NewSpace modalParams={modalParams} closeModal={closeModal} />
        case 'SpaceImageUpdate':
            return <SpaceImageUpdate modalParams={modalParams} closeModal={closeModal} />
        case 'UserImageUpdate':
            return <UserImageUpdate modalParams={modalParams} closeModal={closeModal} />
        case 'StreamImageUpdate':
            return <StreamImageUpdate modalParams={modalParams} closeModal={closeModal} />
        case 'HandCashError':
            return <HandCashError modalParams={modalParams} closeModal={closeModal} />
        case 'Message':
            return <div>{modalParams.message}</div>
        case 'Wallet':
            return <Wallet modalParams={modalParams} closeModal={closeModal} />
    }
}
