import React, { useState, useMemo } from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import styles from 'components/panels/search-header.module.scss'
import FilterIcon from 'components/filters/FilterIcon'
import FilterOptions from 'components/filters/FilterOptions'
import FilterOptionsControl from 'components/filters/FilterOptionsControl'
import SearchBar from 'components/panels/SearchBar'
import useStreamFilter from 'components/filters/useStreamFilters'
import WithTooltip from 'components/shared/WithTooltip'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useSlideToPanel from 'refactor/hooks/useSlideToPanel'
import queryClient from 'api/queryClient'
import { serializeFilterKey } from 'refactor/hooks/api/useGetQuests'
import { Participants, nonUserParticipants } from 'components/people/People'
import { getCachedQuest } from 'state/cache'

export default function SearchHeader(props) {
    const [hover, setHover] = useState(false)
    const { panel, hide } = usePanelContext()
    const panelId = panel.panelId
    const filter = panel.filter
    const query = filter?.query

    //console.log('search header filter', props.panel, filter)
    const users = useStore(state => state.users)
    const DEBUG = useStore(state => state.DEBUG)

    const [showFilterOptions, setShowFilterOptions] = useState()

    const showSearchBar =
        !filter?.notifications &&
        !filter?.history &&
        !filter?.starred &&
        !filter?.people &&
        !filter?.participants

    const isMeme = filter?.meme
    const searchBarPlaceholder = isMeme ? 'Search Meme' : 'Search Stream'
    const filterUser = filter?.user ? users?.find(u => u.id === filter.user) : null
    const minStyles =
        filter?.notifications ||
        filter?.history ||
        filter?.starred ||
        filter.people ||
        filter?.participants
            ? styles.min
            : null

    const memeFilterIconStyles = isMeme ? styles.memeFilterIcon : null
    const memeFilterNameStyles = isMeme ? styles.memeFilterName : null
    const memeControlContainerStyles = isMeme ? styles.memeControlContainer : null

    const slideToPanel = useSlideToPanel()

    const refreshStream = async () => {
        props.setIsRefreshing(true)
        // Invalidate and refetch quests when the panel mounts
        const filterKey = serializeFilterKey(filter)
        await queryClient.refetchQueries({ queryKey: ['quests', filterKey], exact: true })
        props.setIsRefreshing(false)
    }

    const onClickStream = ev => {
        ev.stopPropagation()
        ev.preventDefault()

        refreshStream()
    }

    const lastAction = useStore.getState().panels?.lastAction

    // We get the participantQuest from the prior panel via lastAction so we don't have to wait for the quest.
    let participantQuest = props.quest
    if (
        !participantQuest &&
        panel?.panelId === lastAction?.insert?.panelId &&
        lastAction?.questId
    ) {
        participantQuest = getCachedQuest(lastAction.questId)
    }
    const participants = useMemo(() => nonUserParticipants(participantQuest), [participantQuest])
    const showStream = !filter?.participants

    return (
        <div
            className={cn(styles.searchHeaderComp, `search-header-comp ${props.panel}`, minStyles)}
            style={{ visibility: hide || props.hideDuringScroll ? 'hidden' : 'visible' }}
            onClick={ev => slideToPanel()}
        >
            {DEBUG && (
                <div className={styles.debugContainer}>
                    <span className={styles.debugText}>
                        Filter:{' '}
                        <span
                            style={{ fontFamily: 'monospace', wordWrap: 'break-word' }}
                            onClick={() => {
                                const text = JSON.stringify(filter)
                                navigator.clipboard.writeText(text)
                                // briefly bold the text
                                const spanElement = document.getElementById('filterText')
                                spanElement.style.fontWeight = 'bold'
                                setTimeout(() => {
                                    spanElement.style.fontWeight = 'normal'
                                }, 500)
                            }}
                            id="filterText"
                        >
                            {JSON.stringify(filter)}
                        </span>
                    </span>
                </div>
            )}
            {showStream && (
                <div className={cn(styles.streamControlContainer, memeControlContainerStyles)}>
                    <div className={styles.filterContainer}>
                        <div
                            className={styles.filterIconContainer}
                            onClick={onClickStream}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                        >
                            {hover ? (
                                <WithTooltip tip="Refresh stream.">
                                    <div className={styles.hoverControl}>
                                        <i className="fa fa-refresh" />
                                    </div>
                                </WithTooltip>
                            ) : (
                                <FilterIcon
                                    disable={true}
                                    filter={filter}
                                    panel={props.panel}
                                    user={filterUser}
                                    showNumNoti
                                    contextStyles={cn(
                                        styles.searchHeaderIcon,
                                        memeFilterIconStyles,
                                    )}
                                    initialStyles={styles.initialStyles}
                                    publicIcon={styles.publicIcon}
                                    treechatIcon={styles.treechatIcon}
                                    hodlockerIcon={styles.hodlockerIcon}
                                    twetchIcon={styles.twetchIcon}
                                    notiIcon={styles.notiIcon}
                                    fontIcon={styles.fontIcon}
                                    privateIcon={styles.privateIcon}
                                    linksIcon={styles.linksIcon}
                                    historyIcon={styles.historyIcon}
                                    peopleIcon={styles.peopleIcon}
                                />
                            )}
                        </div>

                        <div
                            className={styles.filterOptionsControlContainer}
                            //onClick={ev => ev.stopPropagation()}
                        >
                            <FilterOptionsControl
                                filter={filter}
                                panel={props.panel}
                                user={gon.currentUser}
                                showFilterOptions={showFilterOptions}
                                setShowFilterOptions={setShowFilterOptions}
                                nameStyles={cn(styles.name, memeFilterNameStyles)}
                                showName
                                disable
                            />
                        </div>
                    </div>
                </div>
            )}

            {showFilterOptions && (
                <div className={styles.filterOptionsContainer}>
                    <FilterOptions
                        origin="search-header"
                        filter={filter}
                        panelId={props.panelId}
                        panel={props.panel}
                        query={props.query}
                        close={() => setShowFilterOptions(!showFilterOptions)}
                        show={['default', 'teams']}
                        theme="dark"
                        setShowFilterOptions={setShowFilterOptions}
                        showNoti
                        blur
                    />
                </div>
            )}

            {filter?.meme && (
                <div className={styles.memeHeaderContainer}>
                    <div className={cn(styles.memeIcon, styles.leftMemeIcon)}>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.outerBracket,
                                styles.outerLeftBracket,
                            )}
                        >
                            [
                        </span>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.innerBracket,
                                styles.innerLeftBracket,
                            )}
                        >
                            [
                        </span>
                    </div>

                    <div className={styles.memeHeader}>{filter?.meme}</div>

                    <div className={cn(styles.memeIcon, styles.rightMemeIcon)}>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.innerBracket,
                                styles.innerRightBracket,
                            )}
                        >
                            ]
                        </span>
                        <span
                            className={cn(
                                styles.bracketIcon,
                                styles.outerBracket,
                                styles.outerRightBracket,
                            )}
                        >
                            ]
                        </span>
                    </div>
                </div>
            )}

            {showSearchBar && (
                <>
                    <div className={styles.searchBarContainer} onClick={ev => ev.stopPropagation()}>
                        <SearchBar
                            key={Object(filter).keys?.join('_')}
                            filter={filter}
                            query={query}
                            panel={props.panel}
                            placeholder={searchBarPlaceholder}
                        />
                    </div>

                    <div
                        className={cn(styles.streamOrderContainer)}
                        onClick={ev => ev.stopPropagation()}
                    >
                        <StreamOrder
                            filter={filter}
                            panelId={panelId}
                            panel={props.panel}
                            query={query}
                        />
                    </div>
                </>
            )}

            {filter?.participants && participants && (
                <div className={styles.participantsContainer}>
                    {participants && (
                        <Participants
                            participants={participants}
                            quest={participantQuest}
                            contextStyles={styles.participants}
                        />
                    )}
                </div>
            )}
        </div>
    )
}

function StreamOrder({ panelId }) {
    const { filter, setStreamOrder, setStreamFilter } = useStreamFilter(panelId)

    const upvote = {
        vote: {
            icon: 'fa fa-caret-up fa-large',
            label: 'Upvotes',
            tip: 'Messages sorted by upvotes',
        },
    }
    const lockvalue = {
        lockvalue: {
            icon: 'fa fa-bitcoin',
            label: 'Top',
            tip: 'Messages sorted by lock value',
        },
    }

    const lockvalueTrending = {
        locktrending: {
            icon: 'fa fa-fire',
            label: 'Hot',
            tip: 'Messages sorted by lock value',
        },
    }
    const elasticRelevance = {
        relev: {
            icon: 'fa fa-search',
            label: 'Relev',
            tip: 'Messages sorted by relevance',
        },
    }
    const semantic = {
        semantic: {
            icon: 'fa fa-magic',
            label: 'Semantic',
            tip: 'Messages sorted by semantic relevance',
        },
    }

    const semanticAndTime = {
        semanticrecency: {
            icon: 'fa fa-clock-o',
            label: 'Recent Sem',
            tip: 'Messages sorted by semantic relevance and recency',
        },
    }

    const onlyLocked = {
        locked: { icon: 'fa fa-lock', label: 'Locked', tip: 'Recently locked messages.' },
    }
    const onlyUnlocked = {
        unlocked: {
            icon: 'fa fa-clock-o',
            label: 'Unlocked',
            tip: 'Recent nocoiner messages.',
        },
    }
    const time = {
        time: { icon: 'fa fa-clock-o', label: 'Recent', tip: 'Recently created messages.' },
    }

    const lockedTime = {
        time: {
            icon: 'fa fa-clock-o',
            label: 'Recent',
            tip: 'Recently created and locked messages.',
        },
    }

    const random = {
        random: { icon: 'fa fa-random', label: 'Random', tip: 'Messages sorted randomly' },
    }

    const yourPosts = {
        yours: { icon: 'fa fa-user', label: 'Your Posts', tip: 'Messages written by you' },
    }

    const filterMapping = (() => {
        const hasSearchQuery = !!filter?.query
        const isPublic = filter?.public || filter?.treechat || filter?.hodlocker
        const isMeme = filter?.meme

        const defaultSearchOrder = { ...time, ...upvote, ...random }
        if (hasSearchQuery) {
            return { ...elasticRelevance, ...semantic, ...semanticAndTime }
        } else if (isPublic) {
            return { ...lockedTime, ...lockvalueTrending, ...lockvalue }
        } else if (isMeme) {
            const [firstItem, ...restItems] = Object.entries(defaultSearchOrder)
            return { [firstItem[0]]: firstItem[1], ...yourPosts, ...Object.fromEntries(restItems) }
        } else {
            return defaultSearchOrder
        }
    })()

    return (
        <div className={styles.streamOrderComp}>
            <div className={styles.orderBtnContainer}>
                {Object.entries(filterMapping).map(([filterKey, filterValue]) => {
                    const isSelected =
                        filter?.order === filterKey ||
                        (filterKey === 'yours' && filter?.user === gon.currentUser.id) ||
                        (!filter?.order && filterKey === Object.keys(filterMapping)[0])
                    const orderBtnStyles = cn(styles.orderBtn, isSelected && styles.selected)
                    const { icon, label, tip } = filterValue
                    return (
                        <div key={label} style={{ flex: 1 }}>
                            <WithTooltip key={filterKey} tip={tip}>
                                <div
                                    className={orderBtnStyles}
                                    onClick={ev => {
                                        ev.stopPropagation()
                                        ev.preventDefault()

                                        if (filterKey === 'yours') {
                                            const newFilter = {
                                                ...filter,
                                                order: filterKey,
                                                from_user: gon.currentUser.id,
                                            }
                                            setStreamFilter(newFilter)
                                        } else {
                                            const newFilter = { ...filter, order: filterKey }
                                            delete newFilter.from_user
                                            setStreamFilter(newFilter)
                                        }
                                    }}
                                >
                                    <i className={icon} />
                                    <span className={styles.orderLabel}>{label}</span>
                                </div>
                            </WithTooltip>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
