import React, { useCallback, useState } from 'react'
import styles from 'components/panels/empty-panel.module.scss'
import cn from 'classnames'
import FilterOptions from 'components/filters/FilterOptions'
import { isMobile } from 'react-device-detect'
import useStore from 'state/knovStore'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useStreamFilters from 'components/filters/useStreamFilters'
import NewQuestButton from 'components/quests/NewQuestButton'
import useRemovePanel from 'refactor/hooks/useRemovePanel'

export default React.memo(function EmptyPanel() {
    const { panel, hide } = usePanelContext()

    const { selectHistory, selectStarred, selectAll } = useStreamFilters(panel.panelId, {
        append: false,
    })

    const panelId = panel.panelId
    const panels = useStore.getState().panels
    const [showFilterOptions, setShowFilterOptions] = useState(false)
    const removePanel = useRemovePanel()
    const onRemovePanel = useCallback(ev => removePanel(), [])

    const panelIx = panels.getIndexById(panelId)
    const canRemove =
        panelIx !== 0 && panelIx !== panels.state.length - 1 && panels.state.length > 3

    return (
        <div
            style={{ visibility: hide ? 'hidden' : 'visible' }}
            className={cn(
                styles.emptyPanelComp,
                showFilterOptions && styles.activePanel,
                'empty-panel-comp',
            )}
        >
            {!showFilterOptions && (
                <div className={styles.controlContainer}>
                    <div
                        className={cn(styles.control, styles.historyControl)}
                        onClick={() => {
                            selectHistory()
                        }}
                    >
                        <i className="fa fa-clock-o" />
                    </div>

                    <div
                        className={cn(styles.control, styles.starredControl)}
                        onClick={() => {
                            selectStarred()
                        }}
                    >
                        <i className="fa fa-star" />
                    </div>

                    <div
                        className={cn(styles.control, styles.allControl)}
                        onClick={() => {
                            selectAll()
                        }}
                    >
                        <i className="fa fa-search" />
                    </div>

                    <NewQuestButton panelId={panelId} append={false}>
                        <div className={cn(styles.control, styles.newThreadControl)}>
                            <i className="fa fa-pencil" />
                        </div>
                    </NewQuestButton>
                </div>
            )}

            <div
                className={cn(
                    styles.streamControlContainer,
                    showFilterOptions && styles.activeContainer,
                )}
                onClick={() => setShowFilterOptions(!showFilterOptions)}
            >
                <div
                    className={cn(
                        styles.filterOptionsContainer,
                        showFilterOptions && styles.activeFilterContainer,
                    )}
                >
                    <FilterOptions
                        close={() => setShowFilterOptions(!showFilterOptions)}
                        show={['default', 'teams']}
                        theme="dark"
                        contextStyles={cn(
                            styles.filterOptions,
                            isMobile && styles.mobileFilterOptions,
                        )}
                        showNoti
                        append={false}
                        panelId={panelId}
                    />
                </div>

                {!isMobile && (
                    <div className={cn(styles.control, showFilterOptions && styles.activeControl)}>
                        <i className="fa fa-bars" />
                    </div>
                )}
            </div>

            {canRemove && (
                <div className={styles.controlContainer} onClick={onRemovePanel}>
                    <div className={cn(styles.control, styles.removePanel)}>
                        <i className="fa fa-remove" />
                    </div>
                </div>
            )}
        </div>
    )
})
