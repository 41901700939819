import React, { useState } from 'react'
import useStore from 'state/knovStore'
import styles from 'components/quests/quest-options.module.scss'
import { OptionList, Option } from 'components/quests/QuestOptions'
import perms from 'lib/perms'
import cn from 'classnames'
import api from 'api/api'
import TypeOptions from 'components/quests/TypeOptions'

import { cacheQuest } from 'state/cache'
import useInsertRight from 'refactor/hooks/useInsertRight'

export default function ExtendedOptions(props) {
    // blog tweet export delete
    const quest = props.quest
    const { insertRight } = useInsertRight()

    const openStoryModal = useStore(state => state.actions.modalActions.openStoryModal)
    const openExportQuestModal = useStore(state => state.actions.modalActions.openExportQuestModal)
    const openImportTextModal = useStore(state => state.actions.modalActions.openImportTextModal)
    const middleQuest = useStore(state => state.middleQuest)
    const middleQuests = useStore(state => state.middleQuests)
    const set = useStore(state => state.set)

    const [showConfirmTweet, setShowConfirmTweet] = useState(false)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [transcribing, setTranscribing] = useState(false)

    const canDelete = () => perms.amQuestOwner(quest)
    const canExport = () => perms.amQuestOwner(quest)
    const canTweet = () =>
        perms.amQuestOwner(quest) && !!gon?.currentUser?.twitter_providers?.length
    const canDuplicate = () => perms.amQuestOwner(quest)
    const canImport = () => perms.amQuestOwner(quest)
    const canTranscribe = () =>
        gon.currentUser?.features?.some(f => f.name === 'transcribe') && perms.amQuestOwner(quest)

    const duplicateQuest = async quest => {
        props.close()
        const [origMiddleQuest, origMiddleQuests] = [middleQuest, middleQuests]
        const { success, quest: dupQuest } = await api.duplicateQuest(quest.id)
        if (success) {
            cacheQuest(dupQuest)
            insertRight({ filter: { questId: dupQuest.id } })
            // TODO PANEL REFACTOR Set Center Panel.
        } else {
            alert('Error duplicating quest :(')
            //set({ middleQuest: origMiddleQuest, middleQuests: origMiddleQuests })
        }
    }
    const transcribeQuestTitle = async (quest, identifySpeakers) => {
        if (transcribing) {
            alert('Transcription in progress.')
        }
        if (!quest?.parent?.transcription) {
            setTranscribing(true)
            const res = await api.transcribeAnswer(quest.parent.id, identifySpeakers)
            if (res?.success) alert('Transcription started.')
            else alert(res.msg)
            setTranscribing(false)
        }
    }

    let options = []

    if (quest.is_draft && canImport()) {
        /*
        // TODO Open Panel using useStreamFilters.
        options.push({
            type: 'icon',
            name: 'Import',
            value: 'import',
            desc: 'Import text as thread.',
            logo: 'fa fa-upload',
            callback: () => openImportTextModal(quest),
        })
        */
    } else {
        options = [
            {
                type: 'icon',
                name: 'Reload Thread',
                value: 'reload',
                desc: 'Reload thread data',
                logo: 'fa fa-refresh',
                callback: () => {
                    api.getQuest(quest.id)
                    props.close()
                },
            },
            {
                type: 'icon',
                name: 'Play',
                value: 'play',
                desc: 'Play thread as full screen story',
                logo: 'fa fa-play',
                callback: () => openStoryModal(quest),
            },
        ]

        if (canTweet()) {
            options.push({
                type: 'icon',
                name: 'Tweet',
                value: 'tweet',
                desc: 'Tweet thread.',
                logo: 'fa fa-twitter',
                callback: () => setShowConfirmTweet(true),
            })
        }

        if (canExport()) {
            options.push({
                type: 'icon',
                name: 'Export',
                value: 'export',
                desc: 'Flatten thread tree into text for export',
                logo: 'fa fa-download',
                callback: () => openExportQuestModal(quest),
            })
        }

        if (canTranscribe()) {
            options.push({
                type: 'icon',
                name: 'Transcribe',
                value: 'transcribe',
                desc: 'Transcribe video.',
                logo: 'fa fa-commenting-o',
                callback: () => transcribeQuestTitle(quest, false),
            })
        }

        if (canTranscribe()) {
            options.push({
                type: 'icon',
                name: 'Identify speakers',
                value: 'identifyspeakers',
                desc: 'Do transcription and identify speakers',
                logo: 'fa fa-commenting-o',
                callback: () => transcribeQuestTitle(quest, true),
            })
        }
        if (canDuplicate()) {
            // TODO PANEL REFACTOR Set Center Panel.
            options.push({
                type: 'icon',
                name: 'Duplicate',
                value: 'duplicate',
                desc: 'Duplicate thread',
                logo: 'fa fa-clone',
                callback: () => duplicateQuest(quest),
            })
        }

        if (canDelete()) {
            options.push({
                type: 'icon',
                name: 'Delete',
                value: 'delete',
                desc: 'Delete thread',
                logo: 'fa fa-remove',
                callback: () => setShowConfirmDelete(true),
            })
        }
    }

    const postTweet = async (providerId, ev) => {
        ev.preventDefault()
        ev.stopPropagation()
        logEv('TWEET_QUEST')

        props.close()
        const res = await props.tweet(providerId).catch(err => {
            console.log(err)
        })
        const data = await res.json()
        if (data.success) {
            alert(
                'Started posting... Upon completion, a link to the twitter thread will be added to the end of this quest.',
            )
        } else {
            alert(
                ('These are too long for tweet:\n\n' + data.failed)
                    .replace(/,\S/g, ', ')
                    .replace(/\\n/g, '\n'),
            )
        }
    }

    // Check old master for how to use this. I think it prevents you from tweeting a quest with unprocessed video.
    const webmCountCheck = quest => {
        return (
            quest.sorted_answers.map(a => a.recording_type).filter(type => type == 'video/webm')
                .length == 0
        )
    }

    const launchSearch = async quest => {
        logEv('LAUNCH_SEARCH')

        var questId = quest.id
        var parentContent = quest.parent.content

        fetch('/quests/launch', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                id: questId,
                question: parentContent,
            }),
        })
            .then(response => response.json())
            .then(data => window.open(data.url, '_blank'))
    }

    const showOptionList = !showConfirmDelete && !showConfirmTweet

    return (
        <div className={styles.extendedOptionsComp}>
            {showOptionList && (
                <>
                    <TypeOptions quest={quest} panel={props.panel} close={props.close} />
                </>
            )}

            {showOptionList && (
                <>
                    <div className={styles.optionsLabel}>Thread Actions</div>

                    <OptionList options={options} close={props.close} />
                </>
            )}

            {showConfirmDelete && (
                <div className={styles.confirmDeleteContainer}>
                    <ConfirmDelete
                        back={() => setShowConfirmDelete(false)}
                        deleteHandler={props.deleteHandler}
                    />
                </div>
            )}

            {showConfirmTweet && (
                <div className={styles.confirmTweetContainer}>
                    <ConfirmTweet back={() => setShowConfirmTweet(false)} postTweet={postTweet} />
                </div>
            )}
        </div>
    )
}

function ConfirmDelete(props) {
    const back = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.back) props.back()
    }

    const deleteHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        props.deleteHandler()
    }

    return (
        <div>
            <div className={styles.confirmDeleteComp}>
                <div className={styles.confirmHeaderContainer}>
                    <div className={styles.backBtn} onClick={back}>
                        <i className="fa fa-arrow-left" />
                    </div>

                    <div className={styles.confirmTitle}>Confirm Delete</div>
                </div>

                <div className={styles.confirmDeleteBtn} onClick={deleteHandler}>
                    <i className="fa fa-close" />
                    <span className={styles.confirmLabel}> Delete Thread </span>
                </div>
            </div>
        </div>
    )
}

function ConfirmTweet(props) {
    const showAccounts = !!gon?.currentUser?.twitter_providers?.length

    const back = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.back) props.back()
    }

    return (
        <div className={styles.confirmTweetComp}>
            {showAccounts && (
                <div className={styles.twitterAccountContainer}>
                    <div className={styles.twitterAccounts}>
                        <div className={styles.twitterAccountsHeader}>
                            <div className={styles.backBtn} onClick={back}>
                                <i className="fa fa-arrow-left" />
                            </div>

                            <div className={styles.twitterTitle}>Tweet from account:</div>
                        </div>

                        {gon.currentUser.twitter_providers.map(twitter => {
                            return (
                                <div
                                    key={twitter.id}
                                    className={styles.twitterUserContainer}
                                    onClick={ev => props.postTweet(twitter.id, ev)}
                                >
                                    <div className={styles.twitterProfileImage}>
                                        <img
                                            src={
                                                twitter.image_url ||
                                                'http://abs.twimg.com/sticky/default_profile_images/default_profile_normal.png'
                                            }
                                        ></img>
                                    </div>
                                    <div className={styles.twitterUsername}>{twitter.username}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            )}
        </div>
    )
}
