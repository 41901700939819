import React from 'react'
import { UserStream } from 'components/filters/FilterIcon'
import CommonEditor from '../shared/CommonEditor'
import UserName from '../users/UserName'
import cn from 'classnames'
import styles from './post-answer.module.scss'
import { getImages, getText } from '../../lib/value'
import WithSelection from 'components/shared/WithSelection'
import AnswerTopControls from 'components/answers/AnswerTopControls'

export default class PostAnswer extends React.PureComponent {
    COLLAPSE_POST_LENGTH = 300

    constructor(props) {
        super(props)
        this.state = {
            showKnovigator: false,
        }
    }

    isUserSignedIn() {
        return document.getElementById('knovigator-body').getAttribute('data-user') === 'true'
    }

    richEditor = React.createRef()
    voteContainerParent = React.createRef()

    // TODO: extract and consolidate getContent(), getEditor(),
    // getDeltaJson(), getContent() with NewAnswer's version
    // here [1]
    // [1]: https://github.com/mitya777/knovigator/blob/refactor/unifiedTooltipsForCopyLinkButton/app/javascript/components/answers/NewAnswer.jsx
    getContent = () => getText(this.getDeltaJson() || {}).trim()
    getEditor = () => this.richEditor.current?.getEditor()
    getDeltaJson = () => this.getEditor()?.getContents()
    getContent = () => getText(this.getDeltaJson() || {}).trim()

    /* truncate a quill delta object to only return number of chars specified */
    truncateDelta(delta, maxChars = this.COLLAPSE_POST_LENGTH) {
        let charCount = 0
        let truncatedDelta = { ops: [] }

        for (const op of delta.ops) {
            if (charCount >= maxChars) break

            if (typeof op.insert === 'string') {
                let remainingChars = maxChars - charCount
                let text = op.insert.slice(0, remainingChars)
                charCount += text.length

                truncatedDelta.ops.push({ ...op, insert: text })

                if (charCount === maxChars) {
                    truncatedDelta.ops.push({ insert: '...' })
                }
            } else {
                truncatedDelta.ops.push(op)
            }
        }

        return truncatedDelta
    }

    render() {
        const { answer, textIsCollapsed, setTextIsCollapsed, COLLAPSE_POST_LENGTH } = this.props
        const isEmbed = this.props.isEmbed
        //console.log('RENDER POST ANSWER', answer.id, answer.delta_json)
        const showContent =
            this.props.editMode ||
            getText(answer.delta_json) ||
            getImages(answer.delta_json) ||
            (isEmbed && (getText(answer.nested_delta_json) || getImages(answer.nested_delta_json)))

        const embedPostContainerStyles = isEmbed ? styles.embedPostContainer : null
        const topPadding = isEmbed ? styles.embedPadding : null
        const showUser = !this.props.blog && !this.props.isStory

        const stylesUserIcon = this.props.isTitle ? styles.titleUserIcon : styles.userIcon

        const isAgent = answer.message_type === 'chatgpt' || answer.message_type === 'agent'

        const truncatedValue =
            isEmbed || (textIsCollapsed && !this.props.editMode)
                ? this.truncateDelta(this.props.value, COLLAPSE_POST_LENGTH)
                : this.props.value

        const showCollapseButton =
            getText(this.props.value)?.length > COLLAPSE_POST_LENGTH && !this.props.editMode

        const systemUser = this.props.systemUser
        const isStory = this.props.isStory

        return (
            <div className={cn(styles.postAnswerComp, topPadding)}>
                <div className={cn(styles.flexContainer)}>
                    <div className={cn(styles.postContainer, embedPostContainerStyles)}>
                        <div className={cn(styles.userAndControlsContainer)}>
                            {showUser && (
                                <div className={styles.userContainer}>
                                    <div className={cn(styles.userIconContainer)}>
                                        <UserStream
                                            filter={this.props.filter}
                                            panel={this.props.panel}
                                            user={answer.user}
                                            agentIcon={isAgent}
                                            virtualUserIcon={answer.virtual_user_icon_url}
                                            showKnovigator={this.props.showKnovigator}
                                            clickable={!isAgent}
                                            contextStyles={
                                                isAgent ? styles.agentIcon : stylesUserIcon
                                            }
                                        />
                                    </div>

                                    <div className={cn(styles.userNameContainer)}>
                                        <UserName
                                            user={answer.user}
                                            answer={answer}
                                            virtualUserName={answer.virtual_user_name}
                                            transcriptSegmentInfo={answer?.transcript?.segment_info}
                                            answerCreatedAt={answer.created_at}
                                            column
                                            showKnovigator={this.props.showKnovigator}
                                            agentModel={
                                                answer.message_type === 'agent'
                                                    ? answer.agent_model
                                                    : null
                                            }
                                        />
                                    </div>
                                </div>
                            )}

                            {!isStory && !systemUser && !isEmbed && (
                                <div className={cn(styles.answerTopControlsContainer)}>
                                    <AnswerTopControls
                                        isTitle={this.props.isTitle}
                                        isBsv={this.props.isBsv}
                                        answer={this.props.answer}
                                        quest={this.props.quest}
                                        hover={this.props.answerHover}
                                        toggleEdit={this.props.toggleEdit}
                                        showControlsPopup={this.props.showControlsPopup}
                                        toggleControlsPopup={this.props.toggleControlsPopup}
                                        archiveConfirm={this.props.archiveConfirm}
                                        toggleArchiveConfirm={this.props.toggleArchiveConfirm}
                                        getPublicLink={this.props.getPublicLink}
                                        isUserSignedIn={this.props.isUserSignedIn}
                                        editMode={this.props.editMode}
                                    />
                                </div>
                            )}
                        </div>

                        {showContent && (
                            // Lesson: have a default style, and then context specific styles.
                            <div
                                className={cn(
                                    styles.contentContainer,
                                    this.props.editMode && styles.editContentContainer,
                                )}
                            >
                                <WithSelection editMode={this.props.editMode}>
                                    <CommonEditor
                                        ref={this.richEditor}
                                        isEmbed={isEmbed}
                                        type="answer"
                                        value={truncatedValue}
                                        //onChange={this.props.onChange}
                                        placeholder="say something..."
                                        setEmbeds={this.props.setEmbeds}
                                        setLinkPreviews={this.props.setLinkPreviews}
                                        autoSave={true}
                                        postHandler={this.props.savePost}
                                        imgHandler={this.props.imgHandler}
                                        readOnly={!this.props.editMode}
                                        answer={this.props.answer}
                                        panel={this.props.panel}
                                        uiId={this.props.uiId}
                                    />
                                </WithSelection>
                            </div>
                        )}

                        {showCollapseButton &&
                            (() => {
                                const baseSize = 16
                                const incSize = 3
                                return (
                                    <div
                                        style={{
                                            width: '100%',
                                            fontSize: `${baseSize}px`,
                                            transition: 'font-size 0.1s ease-in-out',
                                            color: '#aaa',
                                            height: 25,
                                        }}
                                        onClick={e => {
                                            e.stopPropagation()
                                            setTextIsCollapsed(!textIsCollapsed)
                                        }}
                                        onMouseOver={e => {
                                            e.currentTarget.style.fontSize = `${
                                                baseSize + incSize
                                            }px`
                                        }}
                                        onMouseOut={e => {
                                            e.currentTarget.style.fontSize = `${baseSize}px`
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                marginBottom: 15,
                                                height: baseSize,
                                            }}
                                        >
                                            {textIsCollapsed ? (
                                                <i className="fa fa-chevron-down" styles={{}} />
                                            ) : (
                                                <i className="fa fa-chevron-up" />
                                            )}
                                        </div>
                                    </div>
                                )
                            })()}
                    </div>
                </div>
            </div>
        )
    }
}
